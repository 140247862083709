import type { SyntheticEvent } from 'react';

import type { LanguageCode } from './types';

declare global {
  interface Window {
    Optanon: {
      ToggleInfoDisplay(): void;
      changeLanguage(language: string): void;
    };
    dataLayer: Array<unknown>;
    OptanonWrapper(): void;
  }
}

export function installConsentScript(oneTrustId: string): void {
  window.OptanonWrapper = () => {
    // Empty on purpose
  };

  const script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('charset', 'UTF-8');
  script.setAttribute('data-document-language', 'true');
  script.setAttribute('data-domain-script', oneTrustId);
  script.src = `https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`;
  document.body.appendChild(script);
}

export function showConsent(e?: SyntheticEvent): void {
  e?.preventDefault();

  window.Optanon?.ToggleInfoDisplay();
}

function mapToLegalLanguage(language: LanguageCode): string {
  const consentLanguageCode = language.match(/^([a-z]{2})/i)?.[1]; // Extracts the ISO 639 language code using a regex pattern

  if (consentLanguageCode === 'nb') {
    return 'no';
  }

  // eslint-disable-next-line no-warning-comments
  // TODO: Remove this fallback for 'pt' when we have proper translations for the Cookie Consent dialog
  if (!consentLanguageCode || consentLanguageCode === 'pt') {
    return 'en';
  }

  return consentLanguageCode;
}

export function changeConsentLanguage(language: LanguageCode): void {
  const consentLanguage = mapToLegalLanguage(language);
  window.Optanon?.changeLanguage(consentLanguage);
}
