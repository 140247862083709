export const home = '/';
export const dataProtection = '/data-protection';
export const termsOfUse = '/terms-of-use';
export const foss = '/foss';
export const productLabels = '/product-labels';
// Some routes still need to be public after https://dev.azure.com/ZEISSgroup-MED/GEN_Health_Data_Platform/_workitems/edit/378047
export const notfound = '/notfound';
// Public default route, to do fallback when public path can not be routed
export const publicDefault = '/public/*';
// Public page for instructions about how to install PWA on a mobile device
export const instructions = `/public/instructions`;
